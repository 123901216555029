import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "../services/CookieService";

const base_api = 'https://tria.connectria.com';
const cloudAdvisorUrl = 'https://dlfh0e351j.execute-api.us-east-1.amazonaws.com/prod';

const CloudWatchService = {
  getAlarms: async function(regions,params ={}) {
    regions = regions.join(",") || ""
    const idToken = await Cognito.getIdToken();
    const orgId = CookieService.getCookie('OrganizationId');
    return await axios.get(`${cloudAdvisorUrl}/operations/get/cloudwatchalarms`, { 
      params: { regions, orgId }, 
      headers: { Authorization: idToken }
    });
  },
};

export default CloudWatchService;