import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import { appState } from '../../AppState';

import Enum from '../../utility/enum';
import FeatureToggle from '../../utility/FeatureToggle';
import { HamburgerMenu } from '../../components/misc.jsx';
import { ClickstreamPush } from '../Clickstream/Clickstream';

import UserService from '../../services/UserService';
import CookieService from '../../services/CookieService';
import OrganizationService from '../../services/OrganizationService';

import { _removeCachedTicketHistory, _removeCachedUsers } from '../../services/ConnectriaTicketsService';
import Cognito from '../../utility/CognitoAuth';
import { ACCOUNTS_URL } from '../../config';

const Sidebar = ({ isOpen, setIsOpen, routes }) => {
  const menuRef = useRef();
  const [stateApp, stateAppActions] = appState();
  const [visibleGroups, setVisibleGroups] = useState([]);
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);

  const handleOutsideClick = (e) => {
    const menu = menuRef?.current;

    if (isOpen && menu && !menu.contains(e.target)) {
      toggleSidebar(false);
    }
  };

  const toggleSidebar = () => {
    // toggle the side bar open/closed
    setIsOpen(!isOpen);
  };

  const onLinkClick = (isIframe) => {
    // Reload the iframe so it goes back to the original legacy page
    if (isIframe) {
      var iframe = document.getElementById('legacyFrame');
      if (iframe) iframe.src = iframe.src;
    }

    // Anytime an internal link is clicked, close the sidebar
    setIsOpen(false);
    setVisibleGroups([]);
  };

  const onGroupClick = (group) => {
    // Ensure the sidebar is open when clicking on a group
    setIsOpen(true);

    // If the group is already open, close it. Otherwise, open it
    visibleGroups.includes(group)
      ? setVisibleGroups([...visibleGroups.filter((g) => g != group)])
      : setVisibleGroups([...visibleGroups, group]);
  };

  const toggleOrgDropdown = () => {
    // Show the org list and search input if the user is a domain admin and there are multiple orgs to search for
    if (stateApp.organizations?.length > 1) {
      setShowOrgDropdown(!showOrgDropdown);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Ensure all groups and the org search list are closed when the sidebar closes
    if (!isOpen) {
      setVisibleGroups([]);
      setShowOrgDropdown(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (showOrgDropdown) {
      document.getElementById('org-search-input').focus();
    }
  }, [showOrgDropdown]);

  // Routes going outside of TRiA
  // Cannot put in routes.js becuase we need stateApp for the skylift link
  const externalToolsRouteGroup = {
    group: 'Settings & Portals',
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    icon: 'fal fa-cog',
    routes: [
      {
        url: 'https://www.mh.connectria.com',
        name: 'Portal',
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        icon: 'fal fa-external-link-alt',
        isExternal: true,
        clickstreamMeta: {
          event_id: 'clicked',
          event_target: 'portal',
          event_data: {
            category: 'tria',
            source: 'sidebar',
          },
        },
      },
      ...(stateApp.portalCustomerMeta.tria_show_skylift_link === 'TRUE'
        ? [
            {
              url: 'https://apps.connectria.com',
              name: 'Sky-Lift',
              envs: [Enum.Env.ALL],
              clouds: [Enum.CloudCategory.ALL],
              icon: 'fal fa-external-link-alt',
              isExternal: true,
              clickstreamMeta: {
                event_id: 'clicked',
                event_target: 'skylift',
                event_data: {
                  category: 'tria',
                  source: 'sidebar',
                },
              },
            },
          ]
        : []),
      {
        url: 'https://dev.tria.connectria.com',
        name: 'TRiA Legacy',
        envs: [Enum.Env.DEV],
        clouds: [Enum.CloudCategory.ALL],
        rtlName: 'لوحة القيادة',
        icon: 'fal fa-external-link-alt',
        isExternal: true,
        clickstreamMeta: {
          event_id: 'clicked',
          event_target: 'tria-legacy',
          event_data: {
            category: 'tria',
            source: 'sidebar',
          },
        },
      },
    ],
  };

  // change suppress flag by resource property value
  for (const route of routes) {
    if (route.resourceProperty !== undefined) {
      const key = route.resourceProperty.toLowerCase();
      const value = stateApp.resourceProperties[key] || '';
      route.suppress = !!!(parseInt(value) || value === 'true');
    }

    if (route.routes) {
      for (const subroute of route.routes) {
        if (subroute.resourceProperty !== undefined) {
          const key = subroute.resourceProperty.toLowerCase();
          const value = stateApp.resourceProperties[key] || '';
          subroute.suppress = !!!(parseInt(value) || value === 'true');
        }
      }
    }
  }

  // Append the external links to the other routes
  const allRoutes = routes; // [...routes, externalToolsRouteGroup];

  return (
    <div ref={menuRef} className={`left-sidebar ${isOpen ? 'expanded' : ''} py-3`}>
      <div className="burger-container">
        <HamburgerMenu onClick={toggleSidebar} />
      </div>
      <div className="sidebar-thumbnail d-flex align-items-center my-4">
        <div
          className="burger-thumbnail d-flex align-items-center justify-content-center flex-shrink-0 text-uppercase"
          role="button"
        >
          {(stateApp.userInfo?.name || 'User').substr(0, 2)}
        </div>
        <div
          className={'ml-2 slidable w-100' + (stateApp.organizations?.length > 1 ? ' cursor' : '')}
          onClick={toggleOrgDropdown}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p className="text-truncate text-dark mb-0" style={{ maxWidth: '120px' }}>
              {stateApp.organization?.name}
            </p>
            {stateApp.organizations?.length > 1 && <i className="fas fa-caret-down" style={{ color: '#BFC5D2' }} />}
          </div>
          <p className="text-truncate text-header m b small mb-0">{stateApp.userInfo?.name}</p>
        </div>
      </div>

      {showOrgDropdown ? (
        <OrgList isOpen={showOrgDropdown} setSidebarOpen={setIsOpen} />
      ) : (
        <>
          {allRoutes.map((r, key) => {
            return (
              <React.Fragment key={key}>
                {r.group ? (
                  <RouteGroup
                    {...r}
                    isOpen={visibleGroups.includes(r.group)}
                    onGroupClick={onGroupClick}
                    onLinkClick={onLinkClick}
                  />
                ) : (
                  <RouteLink {...r} onLinkClick={onLinkClick} linkIcon={r.icon} />
                )}
              </React.Fragment>
            );
          })}
          <LogoutNavLink toggleSidebar={toggleSidebar} />
        </>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

const OrgList = ({ isOpen, setSidebarOpen }) => {
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();
  const [orgs, setOrgs] = useState(stateApp.organizations);

  const switchOrg = async (org) => {
    Cognito.setOrganizationCookie(org);

    if (stateApp.superAdmin) {
      const divvySession = Cognito.getDivvyCookie();
      if (divvySession) {
        await OrganizationService.switchOrganization(org.customer_id, divvySession);
        // const params = {
        //   payload: {organization_name: org.name},
        //   headers: {'x-token-auth': divvySession}
        // }
        // try {
        //   await OrganizationService.switchOrganization(org.customer_id, divvySession)
        // } catch (err) {
        //   console.error('Error to switch the org', org, err)
        // }
      }
    } else {
      Cognito.deleteDivvyCookies();
    }

    CookieService.eraseCookie('show_skylift', '/');
    stateAppActions.setReload();
    setSidebarOpen(false);
    history.push('/');
    stateAppActions.setCache({ key: 'costSavings', data: [], loading: true });
    _removeCachedUsers();
  };

  const searchOrgs = (e) => {
    const keyword = e.target.value;
    keyword
      ? setOrgs(stateApp.organizations.filter((org) => (org.name || '').toLowerCase().includes(keyword.toLowerCase())))
      : setOrgs(stateApp.organizations);
  };

  return (
    <div className="org-list-container">
      <div className="search-input-container">
        <input
          id="org-search-input"
          type="text"
          className="search-input"
          onChange={searchOrgs}
          placeholder="Search"
        ></input>
        <i className="fal fa-search" />
      </div>
      <div>
        {orgs.map((org, key) => {
          return (
            <div className="sidebar-item" role="button" onClick={() => switchOrg(org)} key={key}>
              <p className="sidebar-item-text slidable text-truncate">{org.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RouteGroup = (props) => {
  const [stateApp, stateAppActions] = appState();

  // Determines whether the group should be highlighted or not
  const [isActive, setIsActive] = useState(false);
  // Get information about the current url the user is at
  const match = useRouteMatch();

  useEffect(() => {
    const routeUrls = props.routes.map((r) => r.layout + r.path);
    // If the group is not open and the current route is inside this group, highlight it
    // If the group is open and the route matches, page will be highlighted
    if (!props.isOpen && routeUrls.includes(match.url)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props.isOpen, match]);

  if (props.roles) {
    const orgAdminCanSee = props.roles.includes(Enum.Roles.orgAdmin) && stateApp.userInfo?.orgAdmin,
      superAdminCanSee = props.roles.includes(Enum.Roles.superAdmin) && stateApp.userInfo?.superAdmin;
    if (!(orgAdminCanSee || superAdminCanSee)) return null;
  }

  // hide menu group
  if (props.suppress) return null;

  return (
    <FeatureToggle policies={props?.policy || []} envs={props.envs} clouds={props.clouds}>
      <SidebarItem
        linkIcon={props.icon}
        name={props.group}
        onClick={() => {
          props.onGroupClick(props.group);
        }}
        className={isActive ? 'active-link' : ''}
      />
      <Collapse isOpen={props.isOpen}>
        {props.routes.map((r, key) => {
          if (r.redirect || r.suppress) return null;
          return (
            <React.Fragment key={key}>
              <RouteLink {...r} onLinkClick={props.onLinkClick} />
            </React.Fragment>
          );
        })}
      </Collapse>
    </FeatureToggle>
  );
};

const RouteLink = (props) => {
  const [stateApp, stateAppActions] = appState();

  if (props.redirect || props.suppress) return null;
  if (props.roles) {
    const orgAdminCanSee = props.roles.includes(Enum.Roles.orgAdmin) && stateApp.userInfo?.orgAdmin,
      superAdminCanSee = props.roles.includes(Enum.Roles.superAdmin) && stateApp.userInfo?.superAdmin;
    if (!(orgAdminCanSee || superAdminCanSee)) return null;
  }

  return (
    <FeatureToggle
      policies={props?.policy || []}
      envs={props.envs}
      clouds={props.clouds}
      integrations={props.integrations}
    >
      {props.isExternal ? (
        <ExternalRouteLink
          url={props.url}
          name={props.name}
          linkIcon={props.linkIcon}
          routeClickstreamMeta={props.clickstreamMeta}
        />
      ) : (
        <InternalRouteLink
          layout={props.layout}
          path={props.path}
          name={props.name}
          onLinkClick={props.onLinkClick}
          linkIcon={props.linkIcon}
          isIframe={props.iframe}
        />
      )}
    </FeatureToggle>
  );
};

const SidebarItem = ({ linkIcon, name, onClick, ...props }) => {
  return (
    <div className={'sidebar-item ' + (props.className || '')} role="button" onClick={onClick}>
      <div className="sidebar-item-icon">{linkIcon && <i className={linkIcon} />}</div>
      <p className="sidebar-item-text slidable">{name}</p>
    </div>
  );
};

const InternalRouteLink = ({ layout, path, name, onLinkClick, linkIcon, isIframe }) => {
  return (
    <NavLink
      to={layout + path}
      activeClassName="active-link"
      onClick={() => {
        onLinkClick(isIframe);
      }}
    >
      <SidebarItem linkIcon={linkIcon} name={name} />
    </NavLink>
  );
};

const ExternalRouteLink = ({ url, name, linkIcon, routeClickstreamMeta }) => {
  const [stateApp, stateAppActions] = appState();

  const clickstreamPushForExternalLink = async (clickstreamMeta) => {
    if (clickstreamMeta) {
      var clickedMeta = {
        event_id: 'clicked',
        event_target: clickstreamMeta.event_target,
        event_data: clickstreamMeta.event_data,
      };
      clickedMeta.event_data['source'] = 'sidebar';
      await ClickstreamPush(stateApp, clickedMeta);
    }
  };

  return (
    <a
      href={url}
      onClick={() => {
        clickstreamPushForExternalLink(routeClickstreamMeta);
      }}
      target="_blank"
    >
      <SidebarItem linkIcon={linkIcon} name={name} />
    </a>
  );
};

const LogoutNavLink = (props) => {
  const [stateApp, stateAppActions] = appState();

  const logout = async (e) => {
    if (e) {
      e.preventDefault();
    }

    await Cognito.logout();

    try {
      await ClickstreamPush(stateApp, {
        event_id: 'clicked',
        event_target: 'logout',
        event_data: {
          category: 'tria',
        },
      });
      await UserService.logout();

      props.toggleSidebar();
      _removeCachedTicketHistory();
      _removeCachedUsers();
    } catch (error) {
      console.log('Error logout', error);
    }

    let cookieDomain = CookieService.getCookieDomain(stateApp.env);
    CookieService.eraseCookie('session_id', '/', cookieDomain);
    CookieService.eraseCookie('session_timeout_time', '/', cookieDomain);
    CookieService.eraseCookie('show_skylift', '/', cookieDomain);

    stateAppActions.setLogout();

    window.location.href = ACCOUNTS_URL + '/sign-in?next=' + window.location.href;
  };

  return (
    <a onClick={logout} style={{ cursor: 'pointer' }} href={'#'}>
      <SidebarItem linkIcon="far fa-power-off" name="Logout" />
    </a>
  );
};

export default Sidebar;
