import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const blueMatadorApiUrl = 'https://3dxgzxfaa4.execute-api.us-east-1.amazonaws.com/prod';
const cloudAdvisorUrl = 'https://dlfh0e351j.execute-api.us-east-1.amazonaws.com/prod';

const BlueMatadorService = {
    _get: async function(endpoint, params) {
      const idToken = await Cognito.getIdToken();
      const orgId = CookieService.getCookie('OrganizationId');

      return await axios.get(`${blueMatadorApiUrl}/${orgId}/${endpoint}`, {
        headers: { Authorization: idToken },
        params
      });
    },
    _post: async function(url, data) {
      const idToken = await Cognito.getIdToken(); 
      const orgId = CookieService.getCookie('OrganizationId');

      return await axios.post(cloudAdvisorUrl + url, data,{
        headers: { Authorization: idToken },
        params: { orgId }
      });
    },
    graphs: async function(params) {
      try {
        return await this._get("graphs", params);
      } catch (ex) {
        return null;
      }
    },
    openedEvents: async function(params) {
      try {
        const endpoint = params.id
          ? "events/" + params.id
          : "events"

        return await this._get(endpoint, params);
      } catch (ex) {
        return null;
      }
    },
    activeEvents: async function(params) {
      try {
        return await this._get("events", params);
      } catch (ex) {
        return null;
      }
    },
    checkSetup: async function() {
      try {
        return await this._get("setup");
      } catch (ex) {
        return null
      }
    },
    pageContent: async function(url) {
      return await this._post("/bluematador/content", {url: url});
    },
};

export default BlueMatadorService;
