

const CookieService = {

    setCookie: function (name, value, days, env, path="") {
        var expires = "";
        let domain = '';
        if (days) {
            let envDomain = CookieService.getCookieDomain(env);
            if (envDomain != null) {
                domain = `.mylightedge.com`

            }
        }
        if (path != "") {
            expires = ";path=" + path;
        }
        document.cookie = name + "=" + (value || "") + ";" + domain + expires;
    },
    getCookieDomain: function(env) {
        //override for qa only to avoid env prod toggles
        if (window.location.hostname.indexOf('qa.') > -1) {
            return '.qa.tria.connectria.com;';
        }
        if (window.location.hostname.indexOf('sandbox.') > -1) {
            return '.sandbox.tria.connectria.com;';
        }
        if(window.location.hostname.indexOf('localhost') == -1) {
            switch(env){
                case 'dev':
                    return '.dev.tria.connectria.com;';
                case 'demo':
                    return '.demo.tria.connectria.com;';
                case 'prod':
                    return '.tria.connectria.com;';
                default: break;
            }
        }

    },
    getCookie: function (name) {
        //fix for multiple cookies with the same name from different domains - return first one that has a value
        const rawCookies = document.cookie.match(new RegExp(`${name}=([^;]*)`, "g"));
        if ((rawCookies == null) || (rawCookies.length == 0)) return null;
        for (var i=0; i < rawCookies.length; i++) {
            let v = rawCookies[i].substr(rawCookies[i].indexOf("="));
            if ((v != null) && (v.length > 1)) {
                return v.substr(1)
            }
        }
        return null;
    },
    eraseCookie: function (name, path="", domain="") {
        let cookie = name + '=; Max-Age=-99999999';
        if (path) cookie += (";path="+path);
        if (domain) cookie += (";domain="+domain);
        document.cookie = cookie;
    },
    deleteAllCookies(prefix = null) {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        if(prefix != null && cookie.toLowerCase().indexOf(prefix) == -1) continue
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    }

};

export default CookieService;