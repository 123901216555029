import React, { useEffect, useState, Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import CookieService from '../services/CookieService';
import BlueMatadorService from '../services/BlueMatadorService';
import ConnectriaBackupService from '../services/ConnectriaBackupService';
import ConnectriaTicketsService from '../services/ConnectriaTicketsService';
import CloudWatchService from '../services/CloudWatchService';
import TrendMicroService from '../services/TrendMicroService';
import PortalService from '../services/PortalService';
import Enum from '../utility/enum';
import { appState } from '../AppState';
import { is_before_date } from '../utility/misc';
import { buildRurl } from './misc';
import IBMService from '../services/IBMService';
import Cognito from './CognitoAuth';
import { ACCOUNTS_URL } from '../config';
import PolicyRouteGuard from './PolicyRouteGuard';
import useQuery from '../hooks/useQuery';

function PrivateRoute({ component: Component, ...rest }) {
  const [stateApp, stateAppActions] = appState();
  const [inFlight, setInFlight] = useState(true);
  const [rurl] = useState(buildRurl());
  const urlQuery = useQuery();

  let _superAdmin = false;
  let _org = null;
  clearCookies();
  async function determineClouds() {
    const orgId = CookieService.getCookie('OrganizationId');
    let ibmi = { data: [] };
    let clouds = { data: [] };
    try {
      clouds = await Cognito.getUserDetail();
      ibmi = await IBMService.setup();
      let roles = clouds.detail.orgs.filter((e) => e.id == orgId);

      let flat_policies = flatten(roles[0]?.roles_v2 || [], 'Policies');
      let policy_codes = flat_policies.map((e) => e.code);
      stateAppActions.setPolicies(policy_codes);

      stateAppActions.setRoles_v2(roles[0]?.roles_v2 || []);
    } catch (e) {
      console.log(e);
    }

    let org = clouds.detail.orgs.filter((e) => e.id == orgId);
    let cloud_accounts = (org[0] ?? {}).cloud_accounts || [];

    if (_superAdmin) {
      let selected_org = await Cognito.getOrganizationById(CookieService.getCookie('OrganizationId'));
      cloud_accounts = [];
      cloud_accounts = cloud_accounts.concat(selected_org.clouds);
    }

    let types = [];
    for (var i = 0; i < cloud_accounts.length; i++) {
      if (cloud_accounts[i].cloud_type.includes('AWS')) {
        types.push(Enum.CloudCategory.AWS);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
      if (cloud_accounts[i].cloud_type.includes('AZURE')) {
        types.push(Enum.CloudCategory.AZURE);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
    }
    //determine if vmware
    //const devicesResult = await ConnectriaTicketsService.getAllDeviceCount({}, { });
    let clientGroupsRes = await ConnectriaBackupService.getCommServeClientGroup();

    if ((clientGroupsRes.data || []).length > 0) types.push(Enum.CloudCategory.VMWARE);

    if (orgId === '3e52ce4e-ced3-41c3-8499-803b227689c5') types.push(Enum.CloudCategory.VMWARE);

    if (ibmi.data.has_ibmi) types.push(Enum.CloudCategory.IBMI);

    stateAppActions.setClouds([...new Set(types)]);
    stateAppActions.setCloudAccounts(cloud_accounts.map((c) => ({ ...c, id: c.id, cloud_type_id: c.cloud_type })));

    return new Promise((resolve) => {
      resolve('resolved');
    });
  }

  async function determineIntegrations() {
    const integrations = [];

    try {
      const hasBlueMatador = await BlueMatadorService.checkSetup();
      if (hasBlueMatador.data.has_bluematador) integrations.push(Enum.Integration.BLUEMATADOR);
    } catch (ex) {
      console.log(ex);
    }

    try {
      const hasTrendMicro = await TrendMicroService.checkSetup();
      if (hasTrendMicro.data.has_trendmicro) integrations.push(Enum.Integration.TRENDMICRO);
    } catch (ex) {
      console.log(ex);
    }

    try {
      const cloudWatchAlarms = await CloudWatchService.getAlarms(['us-east-1', 'us-east-2']);
      if (cloudWatchAlarms.data.clouds_alarms_list.length > 0) integrations.push(Enum.Integration.CLOUDWATCH);
    } catch (ex) {
      console.log(ex);
    }

    stateAppActions.setIntegrations(integrations);

    return new Promise((resolve) => {
      resolve('resolved');
    });
  }

  async function determineSkyLiftLink() {
    try {
      let metadata;
      let show_skylift = CookieService.getCookie('show_skylift');
      if (show_skylift == null) {
        metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_show_skylift_link' });
        show_skylift = metadata.meta_value === 'TRUE' ? 1 : 0;
        CookieService.setCookie('show_skylift', show_skylift, 0, stateApp.env, '/');
      } else {
        metadata = { meta_key: 'Tria_Show_Skylift_Link', meta_value: show_skylift ? 'TRUE' : 'FALSE' };
      }

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise((resolve) => {
        resolve('resolved');
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async function determineUpsellsEnabled() {
    try {
      let metadata;
      let upsellsEnabled = CookieService.getCookie('tria_upsell_ads');
      if (!upsellsEnabled) {
        metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_upsell_ads' });
        CookieService.setCookie('tria_upsell_ads', metadata.meta_value, 0, stateApp.env, '/');
      } else {
        metadata = { meta_key: 'tria_upsell_ads', meta_value: upsellsEnabled };
      }

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise((resolve) => {
        resolve('resolved');
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async function determineResourceProperties() {
    // load resource properties
    const resPropsResp = await Cognito.getResourceProperties(_org);
    if (resPropsResp != null && resPropsResp.success && resPropsResp.resourceProperties != null) {
      const props = {};
      for (const prop of resPropsResp.resourceProperties) {
        props[prop.name] = prop.value;
      }
      stateAppActions.setResourceProperties(props);
    }
  }

  function clearCookies() {
    let validTime = is_before_date(new Date(), new Date(2021, 10, 31));
    let hasCookiesCleared = localStorage.getItem('TRIA_COOKIES_CLEARED');
    if (hasCookiesCleared == null && validTime) {
      CookieService.deleteAllCookies('cognito');
      localStorage.setItem('TRIA_COOKIES_CLEARED', true);
    }
  }
  async function loadData() {
    await Promise.all([
      determineClouds(),
      determineIntegrations(),
      determineSkyLiftLink(),
      determineUpsellsEnabled(),
      determineResourceProperties(),
    ])
      .then() // 1,Error: 2,3
      .catch((e) => console.log(e));

    var d = new Date();
    d.setMinutes(d.getMinutes() + 720);
    CookieService.setCookie('session_timeout_time', d.getTime(), 1, stateApp.env);
    stateAppActions.setSessionTimeoutTime(d.getTime());

    setInFlight(false);
  }

  useEffect(async () => {
    setInFlight(true);
    // refresh cognito token and info when refresh every page
    let org = null,
      superAdmin = false;

    const sessionResponse = await Cognito.getSession();
    if (sessionResponse.success) {
      console.log('We have Cognito cookies');
      console.debug('Make Sure MFA is Enabled');
      let mfaEnabled = await Cognito.hasMfaEnabled();
      if (!mfaEnabled) window.location.href = ACCOUNTS_URL + '/sign-in?next=' + window.location.href;

      try {
        if (sessionResponse.session.idToken.payload['cognito:groups']) {
          console.log('** cognito groups: ', sessionResponse.session.idToken.payload['cognito:groups']);
          superAdmin = sessionResponse.session.idToken.payload['cognito:groups'].indexOf('SuperAdmin') > -1;
          _superAdmin = superAdmin;
        }
      } catch (e) {
        console.error('Error to parse cognito:groups', e);
      }
      const user = { superAdmin: superAdmin, orgAdmin: false };
      stateAppActions.setSuperAdmin(superAdmin);

      const respOrgsAndUser = await Promise.all([
        Cognito.getOrganizations(user, user.superAdmin),
        Cognito.getUserInfo(),
      ])
        .then() // 1,Error: 2,3
        .catch((e) => console.log(e));

      // load organizations
      const orgs = respOrgsAndUser[0];
      stateAppActions.setOrganizations(orgs);
      const orgId = urlQuery.get('org_id');
      if (orgId) {
        Cognito.setOrganizationCookie({ id: orgId });
      }

      org = Cognito.getCurrentOrganization(orgs);
      stateAppActions.setOrganization(org);
      _org = org;

      const userResp = respOrgsAndUser[1];
      if (userResp.success) {
        const userAttrs = userResp.user.attributes;
        user.name = userAttrs.name;
        user.id = userAttrs.sub;
        user.user_id = userAttrs.sub;
        user.email = userAttrs.email;
        user.user_id = userAttrs.sub;
        user.username = userAttrs.email;

        // add legacy fields
        user.organization_id = org ? org.id : null;
        user.organization_name = org ? org.name : '';

        stateAppActions.setAuthenticated(user);
      }
    } else {
      // here we don't have valid Divvy cookie and Cognito token
      // need to authorize
      console.log('We dont have valid Cognito token. Redirect to auth app');
      window.location.href = 'https://login.mylightedge.com/'// ACCOUNTS_URL + '/sign-in?next=' + window.location.href;
    }

    await loadData();
  }, [stateApp.reload]);

  return (
    <Route
      {...rest}
      render={(props) =>
        inFlight ? (
          <></>
        ) : stateApp.authenticated ? (
          <PolicyRouteGuard route={props.location.pathname}>
            <Component {...props} {...rest} />
          </PolicyRouteGuard>
        ) : (
          <Redirect to={`/login${rurl}`} />
        )
      }
    />
  );
}
function flatten(ary, prop) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i][prop])) {
      ret = ret.concat(flatten(ary[i][prop], prop));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}
export default PrivateRoute;
