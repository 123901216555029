import React, {  useEffect} from 'react';
import {appState} from "../../AppState";


const withRole = (roles) => (Component) => (props) => {
    const [stateApp, stateAppActions] = appState();
    console.log(stateApp)
    const userRoles = stateApp.roles;
    let userHasPermission = false;
    if(stateApp.superAdmin)
    {userHasPermission = true;
    }
    else{
        console.log("here are our roles that must be present")
        console.log(roles);
        
    }


    if (userHasPermission) {
     return <Component {...props} />
    }
    return null
   } 

export { withRole }