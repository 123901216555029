import axios from "axios";

export const feed_api = 'https://feed.tria.connectria.com';

const FeedService = {
  
  loadUpsellCard: async (target, options = {}) => {
    try {
      let resp = await axios.get(`${feed_api}/upsell/${target}/index.json`, options);

      return resp.data.frontmatter;
    } catch (error) {
        console.log(error);
    }
    return null;
  },

};

export default FeedService;
