import axios from 'axios';
import Cognito from '../utility/CognitoAuth';
import CookieService from './CookieService';

const cloudAdvisorUrl = 'https://dlfh0e351j.execute-api.us-east-1.amazonaws.com/prod';

const TrendMicroService = {
  _get: async function (url, params, config) {
    const idToken = await Cognito.getIdToken(),
      orgId = CookieService.getCookie('OrganizationId');

    return await axios.get(cloudAdvisorUrl + url, {
      headers: { Authorization: idToken },
      params: { orgId, ...params },
      ...config,
    });
  },
  _post: async function (url, data, config) {
    const idToken = await Cognito.getIdToken(),
      orgId = CookieService.getCookie('OrganizationId');

    return await axios.post(cloudAdvisorUrl + url, data, {
      headers: { Authorization: idToken },
      params: { orgId },
      ...config,
    });
  },
  checkSetup: async function () {
    return await this._get('/trendmicro/setup');
  },
  computers: async function (params = {}) {
    return await this._get('/trendmicro/computers', params);
  },
  antimalware: async function (date, operator, max, config = {}, handleException) {
    try {
      return await this._get(
        '/trendmicro/antimalware',
        {
          dateToSearch: date,
          dateOperator: operator,
          maxItems: max,
        },
        config
      );
    } catch (ex) {
      if (handleException) {
        return null;
      } else {
        throw ex;
      }
    }
  },
  antimalwareEvent: async function (eventId, params = {}, config = {}) {
    return await this._get(`/trendmicro/antimalwareEvent`, { eventId }, config);
  },
  integrity: async function (date, operator, max, config = {}) {
    return await this._get(
      '/trendmicro/integrity',
      {
        dateToSearch: date,
        dateOperator: operator,
        maxItems: max,
      },
      config
    );
  },
};

export default TrendMicroService;
