import React from 'react';

import UpsellCard from './UpsellCard';
import { appState } from '../../AppState';

const UpsellCardContainer = () => {
  const [stateApp] = appState();

  const targets = stateApp.portalCustomerMeta.tria_upsell_ads
    ? stateApp.portalCustomerMeta.tria_upsell_ads.split(',')
    : [];

  return (
    <>
      {targets.map((target) => (
        <UpsellCard key={target} target={target} />
      ))}
    </>
  );
};

export default UpsellCardContainer;
