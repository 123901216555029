import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';

import useAsyncEffect from '../../utility/use-async-effect';
import FeedService, { feed_api } from '../../services/FeedService';

const UpsellCard = ({ target }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const imageBaseUrl = `${feed_api}/upsells/${target}/`;

  const source = axios.CancelToken.source();
  useAsyncEffect(
    async (isMounted) => {
      try {
        setIsLoading(true);
        let resp = await FeedService.loadUpsellCard(target, {
          cancelToken: source.token,
        });
        if (!isMounted()) return;
        setCardDetails(resp);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  if (!isLoading && !cardDetails) {
    return null;
  }

  if (isLoading) {
    return (
      <Card className="card-chart card-home">
        <CardBody className="p-0 text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: 30 }} />
        </CardBody>
      </Card>
    );
  }

  const containerStyle = {
    backgroundColor: cardDetails.background?.color,
    ...(cardDetails.background?.image
      ? { backgroundImage: `url(${imageBaseUrl}background${cardDetails.background?.image?.ext})` }
      : {}),
  };

  console.log('container style: ', containerStyle.backgroundImage);

  const textStyleFn = (styleObj) => ({
    color: styleObj?.color,
    ...(!isMobile && { textAlign: styleObj?.alignment }),
    fontFamily: styleObj?.font?.family,
    fontSize: styleObj?.font?.size * (isMobile ? 0.8 : 1),
    lineHeight: `${styleObj?.font?.lineHeight * (isMobile ? 0.8 : 1)}px`,
    fontWeight: styleObj?.font?.styles?.includes('bold') ? 'bold' : 'normal',
    fontStyle: styleObj?.font?.styles?.includes('italic') ? 'italic' : 'normal',
  });

  const bodyClass = !isMobile && cardDetails.hero?.placement === 'right' ? 'row-reverse' : '';

  const flexAlignmentMap = {
    left: 'flex-start',
    top: 'flex-start',
    center: 'center',
    right: 'flex-end',
    bottom: 'flex-end',
    fill: 'stretch',
  };
  const contentStyle = !isMobile
    ? { flex: 1, justifyContent: flexAlignmentMap[cardDetails.content?.alignment] || cardDetails.content?.alignment }
    : {};

  const buttonStyle = !isMobile
    ? {
        alignSelf: flexAlignmentMap[cardDetails.content?.button?.position],
        ...(cardDetails.content?.button?.position !== 'fill' && { width: cardDetails.content?.button?.width }),
      }
    : {};

  return (
    <Card className="card-chart card-home">
      <CardBody className="p-0">
        <div className={`upsell-card ${!isMobile ? 'desktop' : ''}`} style={containerStyle}>
          <p className="title" style={textStyleFn(cardDetails.heading)}>
            {cardDetails.heading?.text}
          </p>
          <div className={`body ${bodyClass}`}>
            {cardDetails.hero?.image && (
              <div className="hero-wrapper">
                <img src={`${imageBaseUrl}hero${cardDetails.hero?.image?.ext}`} className="hero" alt="Upsell" />
              </div>
            )}
            <div className="content" style={contentStyle}>
              {(cardDetails.content?.descriptions || []).map((d, i) => (
                <p key={i} className="description" style={textStyleFn(d)}>
                  {d.text}
                </p>
              ))}
              {cardDetails.content?.button && (
                <a
                  className="btn btn-lg tria-btn tria-btn-blue action-btn"
                  href={cardDetails.content?.button?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ ...buttonStyle, lineHeight: '10px' }}
                >
                  {cardDetails.content?.button?.title}
                </a>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

UpsellCard.propTypes = {
  target: PropTypes.string,
};

export default UpsellCard;
