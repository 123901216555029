import React, { useState, Fragment, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import startCase from 'lodash/startCase';

import { TriaCard } from '../Cards/TriaCard';
import { CloudLogo } from '../Notifications/componnents';

import ResourcesService from '../../services/ResourcesService';
import IBMService from '../../services/IBMService';
import ConnectriaTicketsService from '../../services/ConnectriaTicketsService';

import { appState } from '../../AppState';
import useAsyncEffect from '../../utility/use-async-effect';
import Enum from '../../utility/enum.js';
import { withRole } from '../RBAC/RbacHOC';
const cloudTypeNameMapping = {
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'Google Cloud',
  vmware: 'VMWare',
  k8s: 'Kubernetes',
  ibmi: 'IBMi',
};

const cloudTypeRouteMapping = {
  aws: '/app/resource-management',
  azure: '/app/resource-management',
  vmware: '/app/resource-management',
  ibmi: '/app/ibm-dashboard',
};

const ResourcesOverview = (props) => {
  const history = useHistory();
  const [clouds, setClouds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stateApp, stateAppActions] = appState();
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async (isMounted) => {
      if (!isMounted()) return;
      setLoading(true);
      let cloudsResult = [];

      try {
        const overviewResult = await ResourcesService.getOverview(source.token);
        cloudsResult = (Array.isArray(overviewResult) ? overviewResult : []).filter((c) => !!c.cloud_type);
      } catch (error) {
        console.log(error);
      }

      try {
        const devicesResult = await ConnectriaTicketsService.getAllDeviceCount({}, { cancelToken: source.token });
        cloudsResult.push({
          cloud_type: 'vmware',
          compute: (devicesResult?.devices || [])[0] || 0,
          total: (devicesResult?.devices || [])[0] || 0,
        });
      } catch (error) {
        console.log(error);
      }

      if (stateApp.clouds.includes(Enum.CloudCategory.IBMI)) {
        try {
          const hostsResult = await IBMService.hosts({ cancelToken: source.token });
          cloudsResult.push({
            cloud_type: 'ibmi',
            compute: hostsResult.data?.length || 0,
            total: hostsResult.data?.length || 0,
          });
        } catch (error) {
          console.log(error);
        }
      }
       cloudsResult = cloudsResult.filter(item => {
        // Extract keys except for cloud_type
        const keys = Object.keys(item).filter(key => key !== "cloud_type");
        // Check if every property except cloud_type is 0
        return !keys.every(key => item[key] === 0);
    });
      setClouds(cloudsResult);
      setLoading(false);
    },
    () => source.cancel(),
    [stateApp.clouds]
  );

  return (
    <TriaCard
      title={
        <div>
          <span>RESOURCE OVERVIEW</span>
          {loading && (
            <i className="fas fa-spinner fa-spin tria-icon large ml-3" style={{ verticalAlign: 'middle' }}></i>
          )}
        </div>
      }
      className="mb-3"
      bodyClassName="px-lg-4 pt-0"
      titleClassName="mb-0"
    >
      <div className="mb-2">
        <table className="table tria-table compact-header white table-indent">
          <thead>
            <tr>
              <th className="cursor">CLOUD</th>
              <th className="cursor text-center">COMPUTE</th>
              <th className="cursor text-center">STORAGE</th>
              <th className="cursor text-center">NETWORK</th>
              <th className="cursor text-center">CONTAINERS</th>
              <th className="cursor text-center">IDENTITY</th>
              <th className="cursor text-center">OTHER</th>
              <th className="cursor text-center">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {clouds?.map((cloud) => {
              const navRoute = cloudTypeRouteMapping[cloud.cloud_type];
              if (navRoute != null) {
                return (
                  <tr key={cloud.cloud_type} className="hovered cursor">
                    <td>
                      <div className="d-flex align-items-center">
                        <CloudLogo type={cloud.cloud_type} size="sm" />
                        <p className="roboto-15 text-dark font-weight-normal ml-2 mb-0">
                          {cloudTypeNameMapping[cloud.cloud_type] || startCase(cloud.cloud_type)}
                        </p>
                      </div>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.compute || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.storage || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.network || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.container || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.identity || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.other || 0}
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <NavLink to={navRoute} className="encode-sans-15 text-blue">
                        {cloud.total || 0}
                      </NavLink>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </TriaCard>
  );
};

export default ResourcesOverview;
