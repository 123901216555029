import axios from 'axios';

import CookieService from './CookieService';
import Cognito from '../utility/CognitoAuth';

const resources_api = 'https://u8v3tg4h98.execute-api.us-east-1.amazonaws.com/prod';

const ResourcesService = {
  elasticSearchResources: async (filterParams, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/es/resources`,
      {
        headers: { Authorization: idToken },
        params: { 
          org_id: CookieService.getCookie('OrganizationId'),
          ...filterParams,
        },
        cancelToken
      },
    );
    for (const hit of response.data.hits) {
     
          hit.resource_type = hit.resource_type =="docdb" ? "rds" : hit.resource_type;
          hit.service = hit.service =="docdb" ? "rds" : hit.service;
       
    }
    return response.data;
  },
  elasticSearchResourceCounts: async (filterParams, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/es/resources/counts`,
      {
        headers: { Authorization: idToken },
        params: { 
          org_id: CookieService.getCookie('OrganizationId'),
          ...filterParams,
        },
        cancelToken
      },
    );
    return response.data;
  },
  exportEndpoint: async (filterParams, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/es/resources/export/get`,
      {
        headers: { Authorization: idToken },
        params: { 
          org_id: CookieService.getCookie('OrganizationId'),
          ...filterParams,
        },
        cancelToken
      },
    );
    return response.data;
  },
  checkExportStatus: async function (filename, params = {}) {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/es/resources/export/status`,
      {
        headers: { Authorization: idToken },
        params: { org_id: CookieService.getCookie('OrganizationId'), filename },
        ...params
      },
    );

    return response;
  },
  getOverview: async (cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/stats/overview`,
      {
        headers: { Authorization: idToken },
        params: { org_id: CookieService.getCookie('OrganizationId') },
        cancelToken
      },
    );
    if (response.data.errors.length > 0) {
      console.log(response.data.errors);
      return [];
    }
    return response.data.data || [];
  },
  getClouds: async (cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/stats/clouds`,
      {
        headers: { Authorization: idToken },
        params: { org_id: CookieService.getCookie('OrganizationId') },
        cancelToken
      },
    );
    if (response.data.errors.length > 0) {
      console.log(response.data.errors);
      return [];
    }
    return response.data.data || [];
  },
  getResourceCategories: async (params, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/stats/categories`,
      {
        headers: { Authorization: idToken },
        params: {
          org_id: CookieService.getCookie('OrganizationId'),
          ...params,
        },
        cancelToken,
      }
    );
    if (response.data.data) {
      console.log('resource catgory response: ', response.data.data);
      return response.data.data;
    }
    return {};
  },
  getFilterOptions: async (params, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/stats/options`,
      {
        headers: { Authorization: idToken },
        params: {
          org_id: CookieService.getCookie('OrganizationId'),
          ...params,
        },
        cancelToken,
      }
    );
    return response.data?.data || {};
  },
  getResources: async (params, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/resources`,
      {
        headers: { Authorization: idToken },
        params: {
          org_id: CookieService.getCookie('OrganizationId'),
          ...params,
        },
        cancelToken,
      }
    );

    return response.data || {};
  },
  getResourceDetail: async (resourceId, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/resource/${resourceId}`,
      {
        headers: { Authorization: idToken },
        params: { org_id: CookieService.getCookie('OrganizationId') },
        cancelToken,
      }
    );
    if (response.data.data) {
      return response.data.data;
    }
    return {};
  },
  exportResources: async (params, cancelToken) => {
    const idToken = await Cognito.getIdToken();
    const response = await axios.get(
      `${resources_api}/export`,
      {
        headers: { Authorization: idToken },
        params: {
          org_id: CookieService.getCookie('OrganizationId'),
          ...params,
        },
        cancelToken,
      }
    );
    return response.data?.data || null;
  },
};

export default ResourcesService;
